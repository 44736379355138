export const registerServiceWorker = async () => {
    if ("serviceWorker" in navigator && "SyncManager" in window) {
      try {
        const registration = await navigator.serviceWorker.register("/service-worker.js");
        console.log("Service Worker Registered");
  
        if ("sync" in registration) {
          await registration.sync.register("sync-location");
          console.log("Background Sync Registered");
        }
      } catch (error) {
        console.error("Service Worker Registration Failed:", error);
      }
    }
  };
  