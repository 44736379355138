import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { CssBaseline } from "@mui/material";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import reportWebVitals from "./reportWebVitals";
import { themeMui } from "./theme";
import { registerServiceWorker } from "./sw-register";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MUIThemeProvider theme={themeMui}>
      <CssBaseline />
      <App />
    </MUIThemeProvider>
  </React.StrictMode>
);

registerServiceWorker();

reportWebVitals();
