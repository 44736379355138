import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const mapContainerStyle = {
  width: "100%",
  height: "100vh",
};

const App = () => {
  const [location, setLocation] = useState(null);
  const [logs, setLogs] = useState([]);
  const [intervalId, setIntervalId] = useState(null);

  const fetchLocation = async () => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
        { method: "POST" } // Empty POST request as required by Google API
      );
      const data = await response.json();
      if (data.location) {
        const newLocation = { lat: data.location.lat, lng: data.location.lng };
        setLocation(newLocation);
        setLogs((prevLogs) => [...prevLogs, newLocation]);
        console.log("Live Location:", data.location.lat, data.location.lng);
      } else {
        console.error("Failed to get location:", data);
      }
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  

  const startTracking = () => {
    fetchLocation(); 
    const id = setInterval(fetchLocation, 10000); 
    setIntervalId(id);
  };

  const stopTracking = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  return (
    <div style={{ padding: "2%" }}>
      <button onClick={startTracking}>Start Tracking</button>
      <button onClick={stopTracking}>Stop Tracking</button>

      <h3>Tracking Logs:</h3>
      <ul>
        {logs.map((log, index) => (
          <li key={index}>
            #{index + 1} - ({log.lat}, {log.lng})
          </li>
        ))}
      </ul>

      <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
        <GoogleMap mapContainerStyle={mapContainerStyle} center={location || { lat: 0, lng: 0 }} zoom={15}>
          {location && <Marker position={location} />}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default App;
